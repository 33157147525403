import React, { Component } from 'react';
import { reduxForm, Field, getFormValues, reset} from 'redux-form';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

import { createServiceAction } from '../../../util/actionHelper.js';
import {  H4, H5, TextInput, Button, IconButton, Spinner, ContentPopup, DropDown } from '../../../components/index.js';
import Grid from '../../../components/grid/Grid.js';
import supplierGridConfig from '../../../configs/gridConfigs/userPage/supplierGridConfig.js';
import { toggleAddSupplerButton } from '../../../actions/navigationActions.js';
import { history } from '../../../util/index.js';
import { clearEditLocationList, clearEditSupplierForm } from '../../../actions/adminUserActions.js';
import {getData, overwrite} from 'country-list';

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#app-host');

// function getLabLocations(locationList) {
//     if (locationList) {
//       return locationList.map(labLocation => ({
//         id: labLocation.locationId,
//         value: labLocation.locationId,
//         label: labLocation.locationName
//       }));
//     }
//     return [];
//   }

export class EditLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValidEmail: false,
      activeNewSupplierAdd: false,
      activeEditLocation: true,
      locationId : null, 
      locationName: null,
      isOpenSupplierList: false,
      supplierID:null,
      OSHId:null,
      isCompanyUpdated: false,
      isLocationUpdated: false,
      isOSHIdUpdated: false,
      supplierModal: false,
    };
    this.newSupplierAdd = this.newSupplierAdd.bind(this);
    this.editLocationButton = this.editLocationButton.bind(this);
 
  }
 
  componentDidUpdate(prevProps) {
    const { supplierLocationList, EditLocationInfoStates, locationDetails, change, supplierId, companyType, companyName, parentCompany,supplierName,locationName } = this.props;
    // if (EditLocationInfoStates && EditLocationInfoStates.locationName && prevProps.EditLocationInfoStates && prevProps.EditLocationInfoStates.locationName !== EditLocationInfoStates.locationName) {
    //   requestLocationDetails(EditLocationInfoStates.locationName);
     
    // }
    // if(EditLocationInfoStates && prevProps.EditLocationInfoStates && EditLocationInfoStates.mLocCode && prevProps.EditLocationInfoStates.mLocCode !== EditLocationInfoStates.mLocCode){
    //   requestLocationDetails(supplierLocationList[0].locationId);
    //   this.setState({isLocationUpdated: true});
    // }
    if(EditLocationInfoStates && prevProps.EditLocationInfoStates && prevProps.EditLocationInfoStates.city&&
      (prevProps.EditLocationInfoStates.city !== EditLocationInfoStates.city||prevProps.EditLocationInfoStates.country !== EditLocationInfoStates.country
        || prevProps.EditLocationInfoStates.address1 !== EditLocationInfoStates.address1 ||prevProps.EditLocationInfoStates.address2 !== EditLocationInfoStates.address2 ||
        prevProps.EditLocationInfoStates.mLocCode !== EditLocationInfoStates.mLocCode || prevProps.EditLocationInfoStates.locationName !== EditLocationInfoStates.locationName)){
      
          this.setState({isLocationUpdated: true});
        }
    if (prevProps.locationDetails && locationDetails !== prevProps.locationDetails) {
      change('address1', locationDetails.address1 ? locationDetails.address1 : '');
      change('address2', locationDetails.address2 ? locationDetails.address2 : '');
      change('country', locationDetails.country ? locationDetails.country : '');
      change('city', locationDetails.city ? locationDetails.city : '');
      change('mLocCode',EditLocationInfoStates && EditLocationInfoStates.mLocCode ? EditLocationInfoStates.mLocCode : '');
      change('OSHId', EditLocationInfoStates && EditLocationInfoStates.OSHId !== undefined ? EditLocationInfoStates.OSHId : '');
     
      this.setState({ locationId: locationDetails.locationId, locationName: locationDetails.locationName })
    }
    if((EditLocationInfoStates && prevProps.EditLocationInfoStates && EditLocationInfoStates.companyName && prevProps.EditLocationInfoStates.companyName !== EditLocationInfoStates.companyName )||
    (EditLocationInfoStates && prevProps.EditLocationInfoStates && EditLocationInfoStates.parentCompany && prevProps.EditLocationInfoStates.parentCompany !== EditLocationInfoStates.parentCompany) ||
    (EditLocationInfoStates && prevProps.EditLocationInfoStates && EditLocationInfoStates.OSHId && prevProps.EditLocationInfoStates.OSHId !== EditLocationInfoStates.OSHId))
    {
      this.setState({isCompanyUpdated: true});
    }
    if(EditLocationInfoStates && prevProps.EditLocationInfoStates && EditLocationInfoStates.OSHId && prevProps.EditLocationInfoStates.OSHId !== EditLocationInfoStates.OSHId){
      this.setState({isOSHIdUpdated: true});
    }
    
    if(supplierId  && prevProps.supplierLocationList !== supplierLocationList && supplierLocationList.length > 0){
      // requestEditLocationLocationList(supplierId)
      // this.setState({
      //   supplierID:supplierId
      // })

    change('supplierID', supplierId ? supplierId : '');
    change('supplierCompanyType', companyType ? companyType : '');
    change('companyName', companyName ? companyName : '');
    change('locationName','');
    change('parentCompany',parentCompany ? parentCompany : '');
    change('supplierName',supplierName ? supplierName : '');
    change('OSHId',supplierLocationList ? supplierLocationList[0].OSHId : '');
    change('mLocCode',supplierLocationList ? supplierLocationList[0].mLocCode : '');
    change('locationName',locationName ? locationName : '');

    }

  }

  filterNewSupplierInfo = (newSupplierInfo) => {
    const resultArray = {}
    const { submitSupplierData, change } = this.props;
    resultArray.supplierName = newSupplierInfo.newSupplierName;
    resultArray.companyType = newSupplierInfo.companyType;
    resultArray.locationName = newSupplierInfo.newLocationName;
    resultArray.address1 = newSupplierInfo.newAddress1;
    resultArray.address2 = newSupplierInfo.newAddress2;
    resultArray.city = newSupplierInfo.newCity;
    resultArray.country = newSupplierInfo.newCountry;
    change('newSupplierName', '');
    change('supplierID', '');
    change('companyType', '');
    change('newLocationName', '');
    change('newAddress1', '');
    change('newAddress2', '');
    change('newCountry', '');
    change('newCity', '');

    submitSupplierData(resultArray);

  }

  filterUpdateLocationInfo = (supplierInfo, locationId, locationName) => {
    const resultArray = {}
    const { updateLocationData, updateCompanyData, supplierId, toggleModal, clearEditSupplierForm } = this.props;
    const {isLocationUpdated, isCompanyUpdated, isOSHIdUpdated } = this.state
   
    if(isCompanyUpdated){
      resultArray.supName = supplierInfo.companyName;
      resultArray.supId = supplierId;
      resultArray.parentCompany=supplierInfo.parentCompany ? supplierInfo.parentCompany :'';
      resultArray.OSHId=supplierInfo.OSHId;
      resultArray.isOnlySupplier= true
      !isOSHIdUpdated && updateCompanyData(resultArray);
    }
    if(isLocationUpdated){
      resultArray.locId = locationId;
      resultArray.locName = supplierInfo.locationName;
      resultArray.addr1 = supplierInfo.address1;
      resultArray.addr2 = supplierInfo.address2;
      resultArray.city = supplierInfo.city;
      resultArray.country = supplierInfo.country;
      resultArray.mLocCode = supplierInfo.mLocCode ? supplierInfo.mLocCode :"";
      resultArray.OSHId=supplierInfo.OSHId;
      !isOSHIdUpdated  && updateLocationData(resultArray);
    }
    if(isOSHIdUpdated ){
      resultArray.isOnlySupplier= false
      resultArray.locId = locationId;
      resultArray.locName = supplierInfo.locationName;
      resultArray.addr1 = supplierInfo.address1;
      resultArray.addr2 = supplierInfo.address2;
      resultArray.city = supplierInfo.city;
      resultArray.country = supplierInfo.country;
      resultArray.mLocCode = supplierInfo.mLocCode ? supplierInfo.mLocCode :"";
      updateCompanyData(resultArray);
  }
    clearEditSupplierForm();
    this.setState({isLocationUpdated: false, isCompanyUpdated: false, isOSHIdUpdated: false });
    this.props.resetForm();
    toggleModal(true);
  }

  newSupplierAdd = (status) => {
    this.setState({ activeNewSupplierAdd: status, activeEditLocation: false });

  }
  resetForm = () => {
    this.props.resetForm();
  } 
  editLocationButton = (status) => {
    this.setState({ activeNewSupplierAdd: false, activeEditLocation: status });
  }
  cancelEditLocation = () => {

    history.push('/');
  };

  openSupplierListModal = () => {
    const { change } = this.props;
    change('supplierCompanyType', '');
    change('locationName', [{ id: '' }])
    change('address1', '');
    change('address2', '');
    change('country', '');
    change('city', '');
    change('mLocCode', [{ mLocCode: '' }])
    this.setState({ isOpenSupplierList: true });
  };

  closeSupplierListModal = () => {
    this.setState({ isOpenSupplierList: false,isLocationUpdated: false,isOSHIdUpdated: false });
  };
 
  toggleEditLocationModal = (status) =>{
    const {toggleModal, resetForm} = this.props;
    resetForm();
    this.setState({isLocationUpdated: false, isCompanyUpdated: false });
    toggleModal(status)

  }
  


  render() {
    const { isModalOpen,
      toggleModal,
      EditLocationInfoStates,
      supplierData,
      errorStatusEditLocation, 
      errorStatusUpdateSupplier,
      clearEditSupplierForm,
      supplierId,
      locationDetails      
    } = this.props;

  const { activeEditLocation, locationId, locationName, isOpenSupplierList, isCompanyUpdated, isLocationUpdated } = this.state;
  overwrite([{
    code:'VN',
    name:'Vietnam'
  }])
  const countryList = getData().map(({
    code: id,
    name:label,
    ...rest
  }) => ({
    id,
    label,
    ...rest
  }));
  return (
      <span>
        {
          (EditLocationInfoStates && locationId === null && locationDetails) ?
            <Spinner large />
            : 
            EditLocationInfoStates && EditLocationInfoStates.supplierID !== "" && locationDetails && locationDetails.length === 0 ?<Spinner large/> :
            <Modal
              isOpen={isModalOpen}
              onRequestClose={() => {clearEditSupplierForm(); this.resetForm(); this.toggleEditLocationModal(false);}}
              className={` ${EditLocationInfoStates !== undefined ? 'modal-container add-supplier-modal-container' : ''}`}
              overlayClassName={`${EditLocationInfoStates !== undefined ? 'modal-overlay' : ''}`}
              >
             {EditLocationInfoStates === undefined  ? <Spinner large/>: EditLocationInfoStates.supplierId === supplierId ? <Spinner large/> : 
              <><IconButton icon="times" className="modal-close-btn" onClick={() => {clearEditSupplierForm(); this.toggleEditLocationModal(false);}} />
              <div className="p2-sm">
                  <H4 className="text-color-accent u-bold page-title-has-back sub-heading">EDIT SUPPLIER LOCATION DETAILS</H4>
                </div>
                <span>
                    <div className="ncss-col-sm-2 inline-flex-display pr0-sm pb3-sm">
                       <H5 className="u-bold description add-supplier-text">Facility Name<span className="mandatory_supplier">*</span></H5>
                    </div>
                    <div className="ncss-col-sm-4 ncss-input-container inline-flex-display pl0-sm pb3-sm ">
                      <Field component={TextInput} name="companyName" type="text" containerClass="u-full-width" />
                    </div>
                    
                    <div className="ncss-col-sm-2 inline-flex-display pr0-sm pb3-sm">
                      <H5 className="u-bold description add-supplier-text">Parent Company Name</H5>
                    </div>
                  <div className="ncss-col-sm-4 ncss-input-container inline-flex-display pl0-sm pb3-sm">
                    
                        <Field component={TextInput} name="parentCompany" type="text" containerClass="u-full-width" />
                       
                  </div>
                    <div className="ncss-col-sm-2 inline-flex-display pr0-sm pb3-sm">
                       <H5 className="u-bold description add-supplier-text">OS ID<span className="mandatory_supplier">*</span></H5>
                    </div>
                    <div className="ncss-col-sm-4 ncss-input-container inline-flex-display pl0-sm pb3-sm">
                      <Field component={TextInput} name="OSHId" type="text" containerClass="u-full-width" />
                    </div>
                    <div className="ncss-col-sm-2 inline-flex-display pr0-sm pb3-sm">
                       <H5 className="u-bold description add-supplier-text">Manufacturing Location Code</H5>
                    </div>
                    <div className="ncss-col-sm-4 ncss-input-container inline-flex-display pl0-sm pb3-sm ">
                      <Field component={TextInput} name="mLocCode" type="text" containerClass="u-full-width" />
                    </div>
                  <div>
                    <div className="ncss-col-sm-2 inline-flex-display">
                     
                          <H5 className="u-bold description p2-top-sm p2-bottom-sm add-supplier-text">Company Type</H5>
                         
                    </div>
                    <div className="ncss-col-sm-4 ncss-input-container inline-flex-display no-padding-supplier">
                      <div className="dropdown-add-supplier">
                       
                          <Field component={TextInput} name="supplierCompanyType" type="text" containerClass="u-full-width" disabled /> 
                         
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="ncss-col-sm-2 inline-flex-display pr0-sm pb3-sm">
                      <H5 className="u-bold description add-supplier-text">Address 1<span className="mandatory_supplier">*</span></H5>
                    </div>
                  <div className="ncss-col-sm-4 ncss-input-container inline-flex-display pl0-sm pb3-sm">
                    
                        <Field component={TextInput} name="address1" type="text" containerClass="u-full-width" />
                       
                  </div>
                 
                    <div className="ncss-col-sm-2 inline-flex-display pr0-sm pb3-sm pl10-sm">
                      <H5 className="u-bold description add-supplier-text ">Address 2</H5>
                    </div> 
                  <div className="ncss-col-sm-4 ncss-input-container inline-flex-display pl0-sm pb3-sm">
                   
                        <Field component={TextInput} name="address2" type="text" containerClass="u-full-width" />
                        
                  </div>
                 
                    <div className="ncss-col-sm-2 inline-flex-display pr0-sm pb3-sm">
                      <H5 className="u-bold description add-supplier-text">Country<span className="mandatory_supplier">*</span></H5>
                    </div> 
                  <div className="ncss-col-sm-4 ncss-input-container inline-flex-display pb3-sm pl0-sm">
                      <Field component={DropDown} name="country" placeholder="Select Country" options={countryList} />

                  </div>
                 
                    <div className="ncss-col-sm-2 inline-flex-display pr0-sm pb3-sm pl10-sm">
                      <H5 className="u-bold description add-supplier-text">City<span className="mandatory_supplier">*</span></H5>
                    </div>
                  <div className="ncss-col-sm-4 ncss-input-container inline-flex-display pl0-sm pb3-sm">
                   
                        <Field component={TextInput} name="city" type="text" containerClass="u-full-width" />
                        
                  </div>
               </div>
       
               </span> 
              <div>

              </div>
              </>}
            
        
        <ContentPopup
          title="Select Supplier:"
          isOpen={isOpenSupplierList}
          closeModal={this.closeSupplierListModal}
          content={
            <Grid rowData={supplierData} columnDefs={supplierGridConfig} onRowClick={this.onRowClick}
              customNoRowTemplete={
                supplierData === undefined
                  ? "<span class='ag-overlay-loading-center'>No results found</span>"
                  : "<span class='ag-overlay-loading-center'>Please wait while your rows are loading</span>"
              }
            />
          }
        />
        { activeEditLocation ?
          <div className="ncss-col-sm-12 ">
            {activeEditLocation ?
              <div className="bottom-section p2-top-sm">
                <Button className="ncss-btn-secondary-dark u-bold secondary-button add-supplier-location-buttton"
                  onClick={() => { clearEditSupplierForm(); toggleModal(true);this.resetForm()}}>
                  CANCEL
                    </Button>
                {isCompanyUpdated || isLocationUpdated ?
                  <span className={`${(typeof errorStatusUpdateSupplier === 'undefined' || errorStatusUpdateSupplier === true) ? 'cursor-not-allowed' : ''}`}>
                    <Button className={`ncss-btn-primary-dark u-bold submit-button-enable u-bold new-material add-supplier-location-buttton ${(typeof errorStatusUpdateSupplier === 'undefined' || errorStatusUpdateSupplier === true || errorStatusEditLocation ===true) ? 'disabled' : ''}`}
                      onClick={() =>  this.filterUpdateLocationInfo(EditLocationInfoStates, locationId, locationName)}>
                      UPDATE 
                    </Button>
                  </span>
                  :
                  null
                }
              </div>
              :
              <div className="ncss-col-sm-12 align-center">
                <Button className="ncss-btn-secondary-dark u-bold secondary-button add-supplier-location-buttton"
                  onClick={() => {toggleModal(true);}}>
                  CANCEL
                      </Button>
                <span className={`${(typeof errorStatusEditLocation === 'undefined' || errorStatusEditLocation === true) ? 'cursor-not-allowed' : ''}`}>
                  <Button className={`ncss-btn-primary-dark u-bold submit-button-enable u-bold new-material add-supplier-location-buttton ${(typeof errorStatusEditLocation === 'undefined' || errorStatusEditLocation === true) ? 'disabled' : ''}`}
                    onClick={() => this.filterNewSupplierInfo(EditLocationInfoStates)}>
                    ADD SUPPLIER
                      </Button>
                </span>
              </div>
            }
          </div>
          : null}
    
  
            </Modal>
        }
      </span>
    );
  }
}

EditLocation.propTypes = {
    requestSupplierList: PropTypes.func,
};
EditLocation.defaultProps = {
  isAdd: true
};

const mapStateToProps = state => ({
  EditLocationInfoStates: getFormValues('editLocationInfo')(state),
  allData: state,
  isAddMaterialModalOpen: state.trf.isAddMaterialModalOpen,
  supplierData: state.adminUser.supplierData,
  supplierLocationList: state.adminUser.supplierLocationList,
  locationDetails: state.adminUser.locationDetails,
  errorStatusEditLocation: state.adminUser.errorStatusEditLocation,
  errorStatusUpdateSupplier: state.adminUser.errorStatusUpdateSupplier,
});

export const mapDispatchToProps = dispatch => ({

  requestEditLocationLocationList: supplierId => {
    dispatch(createServiceAction('addSupplierLocationSearch', 'requested')(supplierId));
  },

  clearEditLocationList: () => {
    dispatch(clearEditLocationList());
  },

  clearEditSupplierForm: () => {
    dispatch(clearEditSupplierForm());
  },

  submitSupplierData: (data) => {
    dispatch(createServiceAction('addSupplier', 'submited')({ ...data }));
  },

  updateLocationData: (data) => {
      dispatch(createServiceAction('locationUpdate', 'submitted')({ ...data }));
  },
  updateCompanyData: (data) => {
    dispatch(createServiceAction('companyUpdate', 'submitted')({ ...data }));
  },

  requestAllSupplierList: () =>{
    dispatch(createServiceAction('listSuppliers','requested')())
  },

  toggleAddSupplerButton: () => {
    dispatch(toggleAddSupplerButton());
  },

  requestLocationDetails: locationId => {
    dispatch(createServiceAction('locationDetails', 'requested')(locationId));
  },

  requestSupplierList: searchTerm => {
    dispatch(createServiceAction('addsupplierSearch', 'requested')(searchTerm));
  },
  resetForm: () => {
    dispatch(reset('editLocationInfo')); // Reset the form values
}
});

export default reduxForm({ form: 'editLocationInfo'
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditLocation));
