import { fromJS } from 'immutable';
import get from 'lodash/get';
import isArray from 'lodash/isArray';

import { selectStatusButton, filterChanged, dispalyColumnsChange, durationChanged, searchAllPage, cancelCalls } from '../actions/dashboardActions.js';
import { createServiceAction } from '../util/actionHelper.js';
import { selectUserDetails } from './authReducer.js';

const defaultState = fromJS({
  selectedButton: 'InProgress',
  getTestSummaryNextPage: 1,
  selectedButtonFlag: false,
  buttonStatus: { ActionRequired: false, InProgress: false, Completed: false },
  stopCall:false,
  isDashboardloading :false,
  enableDashboard :false,
  enableOSHSubmit:false,
  enableLitmos:false
});
let ActionRequired, InProgress, Completed, All, Fav;

export default function dashboard(state = defaultState, action) {
  state = fromJS(state);
  const pageCount = 5000;
  switch (action.type) {
    case selectStatusButton.type:
      let nextpage = 1
      return state.set('selectedButton', action.payload)
        .set('getTestSummaryNextPage', nextpage)
        .set('getTestSummaryTotalPage', 0)
        .set('selectedButtonFlag', true)
    case filterChanged.type:
      return state.set('fliterParams', action.payload);
    case cancelCalls.type:
      return state.set('stopCall', action.payload)

    case searchAllPage.type:
      return state.set('buttonStatus', { 'ActionRequired': false, 'InProgress': false, 'Favorites':false })
                  .set('testData',null)

    case durationChanged.type:
      let tempStatusWithMonth = state.get('statusWithMonth', {})
      let newStatusWithMonth = action.payload.statusWithMonth;

      let updatedStatusMonth = { ...tempStatusWithMonth, ...newStatusWithMonth }

      return state.set('duration', action.payload.month)
        .set('statusWithMonth', updatedStatusMonth)

    case createServiceAction('testSummary', 'loading').type:
      if (state.get('selectedButton') === 'ActionRequired') {
        ActionRequired = false
      }
      if (state.get('selectedButton') === 'InProgress') {
        InProgress = false
      }
      if (state.get('selectedButton') === 'Completed') {
        Completed = false
      }
      if (state.get('selectedButton') === 'All') {
        All = false
      }
      if(state.get('selectedButton') === 'Favorites'){
        Fav= false
      }
      let buttonStatusBegining = { 'ActionRequired': ActionRequired, 'InProgress': InProgress, 'Completed': Completed, 'All': All, 'Favorites':Fav }
      return state.set('testData',null)
        .set('buttonStatus', buttonStatusBegining).set('isDashboardloading',true)

    case createServiceAction('testSummary', 'success').type:
      let nextPage = 1
      let totalPage_success = 0
      if (get(action.data, 'data.totalCount') > pageCount) {
        nextPage = parseInt(get(action.data, 'data.currentPage'), 10) + 1
        totalPage_success = Math.ceil(parseInt(get(action.data, 'data.totalCount'), 10) / pageCount)
      }
      let tempForAll = action.data
      let updateTempForAll = fromJS(tempForAll)
      let allData = [...get(action.data, `data.statusData.ActionRequired`, []), ...get(action.data, `data.statusData.InProgress`, []), ...get(action.data, `data.statusData.Completed`, [])]

      updateTempForAll = updateTempForAll.updateIn(['data', 'statusData', 'All'], index => allData)
      updateTempForAll = updateTempForAll.updateIn(['data', 'statusData', 'Favorites'], index => get(action.data, `data.statusData.Favorites`, []))

     
      return state.set('testData', updateTempForAll.toJS())
        .set('summaryTotalCount', get(action.data, 'data.totalCount'))
        .set('getTestSummaryTotalPage', totalPage_success)
        .set('getTestSummaryNextPage', nextPage)
        .set('getMaterialRemainingPages', Math.ceil(parseInt(get(action.data, 'data.totalCount'), 10) / pageCount) - parseInt(get(action.data, 'data.currentPage'), 10))
        .set('stopCall',false)
        .set('isDashboardloading',false)
      case createServiceAction('testSummary', 'server-error').type:
          return state.set( `testData`, [])
    case createServiceAction('testSummaryLazyLoad', 'success').type:

      let newActionRequired = state.get('selectedButton') === 'All' ?
        get(action.data, `data.statusData.ActionRequired`, []) : []
      let newInProgress = state.get('selectedButton') === 'All' ?
        get(action.data, `data.statusData.InProgress`, []) : []
      let newCompleted = state.get('selectedButton') === 'All' ?
        get(action.data, `data.statusData.Completed`, []) : []


      let newData =
        state.get('selectedButton') === 'All' ?
          [...newActionRequired, ...newInProgress, ...newCompleted]
          :
          action.data.data.statusData[state.get('selectedButton')]


      let temp = state.get('testData')
      let updateTemp = fromJS(temp)

      let InitialData = temp.data.statusData[state.get('selectedButton')]

      InitialData = [...InitialData, ...newData];


      updateTemp = updateTemp.updateIn(['data', 'currentPage'], data => action.data.data.currentPage)
      updateTemp = updateTemp.updateIn(['data', 'statusData', state.get('selectedButton')], index => InitialData)
      let nextSummaryPage = parseInt(get(action.data, 'data.currentPage'), 10) + 1;
      let totalPage = Math.ceil(parseInt(get(action.data, 'data.totalCount'), 10) / pageCount)
      if (nextSummaryPage > state.get('getTestSummaryTotalPage')) {
        nextSummaryPage = 1
        totalPage = 0
      }
      console.log('statusLoadFinish',  updateTemp.toJS())
      return state.setIn(['testData'], updateTemp.toJS())
        .set('getTestSummaryTotalPage', totalPage)
        .set('getTestSummaryNextPage', nextSummaryPage)
        .set('getMaterialRemainingPages', Math.ceil(parseInt(get(action.data, 'data.totalCount'), 10) / pageCount) - parseInt(get(action.data, 'data.currentPage'), 10))
        //.set('stopCall',false)
    case createServiceAction('testSummaryLazyLoad', 'completed').type:
      if (state.get('selectedButton') === 'ActionRequired') {
        ActionRequired = true
      }
      if (state.get('selectedButton') === 'InProgress') {
        InProgress = true
      }
      if (state.get('selectedButton') === 'Completed') {
        Completed = true
      }
      if (state.get('selectedButton') === 'All') {
        All = true
      }
      if(state.get('selectedButton') === 'Favorites'){
        Fav = true
      }
      let buttonStatus = { 'ActionRequired': ActionRequired, 'InProgress': InProgress, 'Completed': Completed, 'All': All, 'Favorites':Fav }
      return state.set('buttonStatus', buttonStatus);


    case createServiceAction('testSummaryCount', 'success').type:
      return state.set('testSummaryCount', action.data);

    case createServiceAction('testDetails', 'success').type:
      return state.set('testDetailData', action.data);
    case createServiceAction('getFilterHistory', 'success').type:
      const searchData = action.data.searchData;
      let disableClearBtn = true;
      if (searchData) {
        for (let k = 0; k < searchData.filter.length; k++) {
          if (searchData.filter[k].value != null) {
            if ((isArray(searchData.filter[k].value) && searchData.filter[k].value.length > 0) || !isArray(searchData.filter[k].value)) {
              disableClearBtn = false;
            }
          }
        }
        return state.set('fliterParams', searchData.filter).set('gridColumns', searchData.columns).set('initgridColumns', searchData.columns).set('disableClearButton', disableClearBtn);
      }
      return state.set('disableClearButton', disableClearBtn);
    case dispalyColumnsChange.type:
      return state.set('gridColumns', action.payload);
    case createServiceAction('saveFilterData', 'success').type:
      const searchParams = action.data.searchParams;
      let disableClear = true;
      if (searchParams) {
        for (let j = 0; j < searchParams.filter.length; j++) {
          if (searchParams.filter[j].value != null) {
            if ((isArray(searchParams.filter[j].value) && searchParams.filter[j].value.length > 0) || !isArray(searchParams.filter[j].value)) {
              disableClear = false;
            }
          }
        }
      }
      return state.set('fliterParams', searchParams.filter).set('gridColumns', searchParams.columns).set('initgridColumns', searchParams.columns).set('disableClearButton', disableClear);;
    case createServiceAction('enableDashboard', 'requested').type:
      return state.set('enableDashboard', action.data);
    case createServiceAction('enableOSHSubmit', 'requested').type:
        return state.set('enableOSHSubmit', action.data); 
    case createServiceAction('enableLitmos', 'requested').type:
      return state.set('enableLitmos', action.data);
    default:
      return state;
  }
}

export const getSelectedButton = state => state.dashboard.get('selectedButton');

export const getTestData = state => {
  const testData = state.dashboard.get('testData');

  const selectedButton = state.dashboard.get('selectedButton')
  if (testData) {
    // Update test summary data with Lab users assigned Lab location id
    // This is done for further checks in Lab dashboard grid

    get(testData, `data.statusData[${selectedButton}]`, []).map((summary) => {
      summary.userLabLocationId = parseInt(selectUserDetails(state).labLocationId, 10);
      return summary;
    });
  }
  return testData;
}

export const getTestSummaryCount = state => state.dashboard.get('testSummaryCount');

export const getTestDetailData = state => state.dashboard.get('testDetailData');
// test Summary
export const getTestSummaryNextPage = state => state.dashboard.get('getTestSummaryNextPage');
export const getMaterialRemainingPages = state => state.dashboard.get('getMaterialRemainingPages');
export const getTestSummaryTotalPage = state => state.dashboard.get('getTestSummaryTotalPage');
export const getfliterParams = state => state.dashboard.get('fliterParams');
export const getGridColumns = state => state.dashboard.get('gridColumns');
export const getInitGridColumns = state => state.dashboard.get('initgridColumns');
export const getDisableClearButton = state => state.dashboard.get('disableClearButton');
export const getSelectedButtonFlag = state => state.dashboard.get('selectedButtonFlag');
export const buttonStatus = state => state.dashboard.get('buttonStatus');
export const getDuration = state => state.dashboard.get('duration');
export const getStatusWithMonth = state => state.dashboard.get('statusWithMonth');
export const stopCall = state => state.dashboard.get('stopCall');
export const isDashboardloading= state => state.dashboard.get('isDashboardloading')
export const getEnableDashboard = state => state.dashboard.get('enableDashboard');
export const getEnableOSHSubmit = state => state.dashboard.get('enableOSHSubmit');
export const getEnableLitmosSubmit = state => state.dashboard.get('enableLitmos');