import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import { Container, H1, Button, ConfirmationBox, ProgressBar, Spinner } from '../../components/index.js';
import StatusArea from '../status_area/StatusArea.js';
import DonutChart from '../../components/donutchart/DonutChart.js';
import Grid from '../../components/grid/Grid.js';
//import testGridConfig from '../../configs/gridConfigs/dashboard/testGridConfig.js';
import dashboardConfig from '../../configs/gridConfigs/dashboard/dashboardConfig';
import {
  getSelectedButton, getTestData, getTestSummaryCount, getTestSummaryNextPage,
  getTestSummaryTotalPage, getfliterParams, getGridColumns, getInitGridColumns,
  getSelectedButtonFlag, buttonStatus, getDuration, getStatusWithMonth, getEnableDashboard
} from '../../reducers/dashboardReducer.js';
import { createServiceAction } from '../../util/actionHelper.js';
//import moment from 'moment';
import {
  isSupplierUser,
  isLabUser,
  isRslUser,
  isFactoryUser,
  isNloUser,
  selectUserDetails,
  supplierName,
  getFavourites,
  loginAuthFinish
} from '../../reducers/authReducer.js';
import { selectStatusButton, durationChanged, cancelCalls, filterChanged, searchAllPage, dispalyColumnsChange } from '../../actions/dashboardActions';
import { clearArchive } from '../../actions/archiveActions.js'

import pageConstants from './Dashboard.constants.js';
import ActionCenter from '../welcome/actionCenter.js';

export class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitModal: false,
      status: null,
      trfId: null,
      filterParams: null,
      showFilterButton: true,
      gridApi: null,
      params: null,
      statusChange: false,
      buttonStatus: { ActionRequired: false, InProgress: false, Completed: false, All: false, Favorites: false },
      threeMonthActive: true,
      oneYearActive: false,
      twoYearActive: false,
      isDashboardloadingState: props.isDashboardloading 
    };
    this.createStatusAreaData = this.createStatusAreaData.bind(this);
    this.createChartData = this.createChartData.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onPaginationChanged = this.onPaginationChanged.bind(this);
    this.filterValues = this.filterValues.bind(this);
    this.getPageTitle = this.getPageTitle.bind(this);
    this.setGridParams = this.setGridParams.bind(this);
    this.cancelstatus = this.cancelstatus.bind(this);
    this.updatestatusValue = this.updatestatusValue.bind(this);
    this.fetchTestSummary = this.fetchTestSummary.bind(this);
    this.searchAllDetails = this.searchAllDetails.bind(this);
    sessionStorage.removeItem("search_all");
  }

  componentDidMount() {
    const { isLabUser, requestTestSummaryCount, requestTestSummary, supplierName, statusLoadFinish, selectedButton, requestCancelCall, loginAuthFinish,userDetails } = this.props;
    let defaultStatus = sessionStorage.getItem('activeStatus') ? sessionStorage.getItem('activeStatus') : 'ActionRequired';
    // Default option to be selected is different for lab and other users

    if (isLabUser) {
      defaultStatus = sessionStorage.getItem('activeStatus') ? sessionStorage.getItem('activeStatus') : 'InProgress';
    }
    let differenceMonth = sessionStorage.getItem('currentSelectedMonth') ? sessionStorage.getItem('currentSelectedMonth') : 12;
    let day = new Date()
    //const formatStr = 'YYYY-MM-DD';
    day.setMonth(day.getMonth() - differenceMonth)
    //fromDate = moment.utc(day).format(formatStr)
    let dateArray = day.toLocaleDateString('en-US').split('/')


    let fromDate = dateArray[1] + "-" + dateArray[0] + "-" + dateArray[2]
    sessionStorage.removeItem("search_all");
    if (Number(differenceMonth) === 3 || differenceMonth === '3') {
      this.setState({ threeMonthActive: true, twoYearActive: false, oneYearActive: false })
    } else if (Number(differenceMonth === 24 || differenceMonth === '24')) {
      this.setState({ threeMonthActive: false, twoYearActive: true, oneYearActive: false })
    } else if (Number(differenceMonth) === 12 || differenceMonth === '12') {
      this.setState({ threeMonthActive: false, twoYearActive: false, oneYearActive: true })
    }
    if (loginAuthFinish) {
      requestCancelCall(true)

      if (fromDate && !userDetails.userInactive) {
        // requestTestSummaryCount(false, defaultStatus, supplierName, defaultStatus !== 'Favorites' ? fromDate : null);
        requestTestSummaryCount(false, defaultStatus, supplierName, fromDate,userDetails.facilityName);
      }

      if (!statusLoadFinish[selectedButton] && !userDetails.userInactive) {
        requestCancelCall(false)
        requestTestSummary(defaultStatus, supplierName, fromDate,userDetails.facilityName);
      }
    }

  }
  componentWillUpdate(nextProps, nextState) {
    const { selectedButton, loginAuthFinish, supplierName, isLabUser, statusLoadFinish, requestCancelCall, requestTestSummary, requestTestSummaryCount,userDetails } = this.props;
    const { params } = this.state;
    if (!params && params !== nextState.params && selectedButton && !nextState.statusChange) {
      let page = sessionStorage.getItem(`${nextProps.selectedButton}PageNumber`) ? sessionStorage.getItem(`${nextProps.selectedButton}PageNumber`) : 0;

      nextState.params && nextState.params.api.paginationGoToPage(Number(page))
    }
    if (selectedButton !== nextProps.selectedButton && (nextProps.selectedButton === 'ActionRequired' || nextProps.selectedButton === 'InProgress' || nextProps.selectedButton === 'Favorites')) {
      if (nextProps.selectedButton === 'ActionRequired') {
        sessionStorage.removeItem('InProgress')
        sessionStorage.removeItem('Favorites')
      } else if (nextProps.selectedButton === 'InProgress') {
        sessionStorage.removeItem('ActionRequired')
        sessionStorage.removeItem('Favorites')
      } else if (nextProps.selectedButton === 'Favorites') {
        sessionStorage.removeItem('ActionRequired')
        sessionStorage.removeItem('InProgress')
      }

    }
    if (!loginAuthFinish && nextProps.loginAuthFinish && loginAuthFinish !== nextProps.loginAuthFinish) {
      let defaultStatus = sessionStorage.getItem('activeStatus') ? sessionStorage.getItem('activeStatus') : 'ActionRequired';
      // Default option to be selected is different for lab and other users

      if (isLabUser) {
        defaultStatus = sessionStorage.getItem('activeStatus') ? sessionStorage.getItem('activeStatus') : 'InProgress';
      }
      const fromDate = this.generateDates()
      requestCancelCall(true)

      if (fromDate) {
        // requestTestSummaryCount(false, defaultStatus, supplierName, defaultStatus !== 'Favorites' ? fromDate : null);
        requestTestSummaryCount(false, defaultStatus, supplierName, fromDate,userDetails.facilityName);
      }
      if (!statusLoadFinish[selectedButton]) {
        requestCancelCall(false)
        requestTestSummary(defaultStatus, supplierName, fromDate, userDetails.facilityName);
      }


    }


  }
  confirmationBoxOpen = (modalopen, status, trfId) => {

    this.setState({ submitModal: true, status, trfId });

  };
  componentDidUpdate(prevProps) {
    if (prevProps.isDashboardloading !== this.props.isDashboardloading) {
      this.setState({ isDashboardloadingState: this.props.isDashboardloading });
    }
  }
  
  generateDates = () => {
    let differenceMonth = sessionStorage.getItem('currentSelectedMonth') ? sessionStorage.getItem('currentSelectedMonth') : 12;
    let day = new Date()
    //const formatStr = 'YYYY-MM-DD';
    day.setMonth(day.getMonth() - differenceMonth)
    //fromDate = moment.utc(day).format(formatStr)
    let dateArray = day.toLocaleDateString('en-US').split('/')


    let fromDate = dateArray[1] + "-" + dateArray[0] + "-" + dateArray[2]
    sessionStorage.removeItem("search_all");
    if (Number(differenceMonth) === 3 || differenceMonth === '3') {
      this.setState({ threeMonthActive: true, twoYearActive: false, oneYearActive: false })
    } else if (Number(differenceMonth === 24 || differenceMonth === '24')) {
      this.setState({ threeMonthActive: false, twoYearActive: true, oneYearActive: false })
    } else if (Number(differenceMonth) === 12 || differenceMonth === '12') {
      this.setState({ threeMonthActive: false, twoYearActive: false, oneYearActive: true })
    }
    return fromDate
  }

  updatestatusValue = () => {
    const { updateStatus } = this.props
    const { trfId } = this.state
    const requestObject = {}
    requestObject.trfId = trfId
    updateStatus(requestObject)
  }
  closeSubmitModal = () => {
    this.setState({ submitModal: false });
  };

  cancelstatus = () => {
    const { history } = this.props
    history.push('/');
  }
  onCellClicked = params => {
    const { isLabUser, isRslUser, isSupplierUser, isFactoryUser, updateFavourites, isNloUser, selectedButton, supplierName } = this.props
    const colId = params.api.getFocusedCell().column.colId
    const statusValue = params.data.action
    const trfId = params.data.trfId

    let differenceMonth = sessionStorage.getItem('currentSelectedMonth') ? sessionStorage.getItem('currentSelectedMonth') : 12;
    let day = new Date()
    day.setMonth(day.getMonth() - differenceMonth)
    let dateArray = day.toLocaleDateString('en-US').split('/')


    let fromDate = dateArray[1] + "-" + dateArray[0] + "-" + dateArray[2]

    if (isLabUser) {
      if (colId === 'proceedAction') {

        if (statusValue === 'Review') {
          this.confirmationBoxOpen(true, 'submit', trfId)
        }
      }

    } else if ((isRslUser || isSupplierUser || isFactoryUser || isNloUser) && colId === 'favourites') {
      if (params.event.target.checked !== undefined) {
        updateFavourites({ trfId: params.data.trfId, status: params.event.target.checked, buttonStatus: selectedButton, supplierName: supplierName, fromDate: fromDate });
      }
    }
  }

  onRowClick = params => {
    const colId = params.api.getFocusedCell().column.colId
    const { history, isLabUser, isRslUser, isNloUser, selectedButton, requestCancelCall, userId } = this.props;
    if (colId !== 'proceedAction' && isUndefined(params.event.target.checked)) {
      requestCancelCall(true)
      if (isLabUser) {
        history.push('/labresult', { trfId: params.data.trfId });
      }
      else if (selectedButton === 'Favorites' && params.data.action === 'Review') {
        history.push('/trf', { trfId: params.data.trfId,preLoadMaterial:true });
      }
      else if (selectedButton !== 'InProgress' && params.data.overAllStatus !== 'In Progress') {
        history.push('/test-details', { trfId: params.data.trfId, rslId: params.data.rsl });
      }
      else if (isRslUser || isNloUser) {
        if (params.data.action === 'Review' && params.data.userId === userId) {
          history.push('/trf', { trfId: params.data.trfId, isPartialEdit: false,preLoadMaterial:true });
        }
        else if ((params.data.overAllStatus === 'In Progress' && params.data.userId !== userId) ||
          (params.data.action === 'Test pending with Lab' && params.data.userId === userId)) {
          history.push('/trf', { trfId: params.data.trfId, isPartialEdit: true,preLoadMaterial:true });
        }
        else {
          history.push(`/download-trf`, { trfId: params.data.trfId });
        }
      }
      else {
        if (params.data.frfId) {
          history.push(`/download-frf`, { trfId: params.data.trfId, qrfId: params.data.qrfId, frfId: params.data.frfId });
        }
        else if (params.data.qrfId) {
          history.push(`/download-qrf`, { trfId: params.data.trfId, qrfId: params.data.qrfId });
        }
        else if (params.data.action === 'Review') {
          history.push('/trf', { trfId: params.data.trfId,preLoadMaterial:true });
        }
        else {
          history.push(`/download-trf`, { trfId: params.data.trfId });
        }
      }
    }
  };

  getPageTitle = () => {
    const { isRslUser, isLabUser, isFactoryUser, isNloUser } = this.props;
    if (isRslUser || isNloUser) {
      return pageConstants.rslUserPageTitle;
    }
    if (isLabUser) {
      return pageConstants.labUserPageTitle;
    }
    if (isFactoryUser) {
      return pageConstants.factoryPageTitle;
    }
    return pageConstants.supplierPageTitle;
  }

  setGridParams = params => {
    const { isRslUser, isLabUser, isSupplierUser, isFactoryUser, isNloUser } = this.props;
    // Diplaying columns based on user group
    const rslUserColumns = isRslUser && [...pageConstants.rslUserColumns];
    const nloUserColumns = isNloUser && [...pageConstants.rslUserColumns];
    const labUserColumns = isLabUser && [...pageConstants.labUserColumns];
    const supplierUserColumns = isSupplierUser && [...pageConstants.supplierUserColumns];
    const factoryUserColumns = isFactoryUser && [...pageConstants.factoryUserColumns];

    const columnsToDisplay = rslUserColumns || labUserColumns || supplierUserColumns || factoryUserColumns || nloUserColumns;
    this.setState({ gridApi: params.api, params: params });
    const allColumns = params.columnApi.getColumnState();
    const filteredColumns = allColumns.map(column => {
      if (columnsToDisplay.indexOf(column.colId) === -1) {
        column.hide = true;
      } else {
        column.hide = false;
      }
      return column;
    });
    params.columnApi.setColumnState(filteredColumns);
    // Fix for IE
    params.api.sizeColumnsToFit();
  }

  createStatusAreaData = () => {
    const { isLabUser, testSummaryCount } = this.props;

    this.actionRequiredObj = {
      name: 'ACTION REQUIRED',
      value: 'ActionRequired',
      icon: 'exclamation',
      count: get(testSummaryCount, `data.ActionRequired`, 0)
    };
    this.inProgressObj = {
      name: 'IN PROGRESS',
      value: 'InProgress',
      icon: 'hand-paper',
      count: get(testSummaryCount, `data.InProgress`, 0)
    };
    this.completedObj = {
      name: 'COMPLETED',
      value: 'Completed',
      icon: 'thumbs-up',
      count: get(testSummaryCount, `data.Completed`, 0)
    };
    this.viewAllObj = {
      name: 'SEARCH ALL',
      value: 'All',
      icon: 'search',
      count: parseInt(get(testSummaryCount, `data.Completed`, 0)) +
        parseInt(get(testSummaryCount, `data.InProgress`, 0)) +
        parseInt(get(testSummaryCount, `data.ActionRequired`, 0))
    };
    this.favouriteObj = {
      name: 'FAVORITES',
      value: 'Favorites',
      icon: 'heart',
      count: get(testSummaryCount, `data.Favorite`, 0)
    };
    if (isLabUser) {
      return [this.inProgressObj, this.viewAllObj];
    }
    return [this.actionRequiredObj, this.inProgressObj, this.viewAllObj, this.favouriteObj];
  }

  createChartData = () => {
    const { isLabUser, testSummaryCount } = this.props;
    this.chartData = [
      {
        label: 'Pass',
        color: '#87E300',
        value: get(testSummaryCount, `data.passCount`, 0)
      },
      {
        label: 'Fail',
        color: '#FF5A00',
        value: get(testSummaryCount, `data.failCount`, 0)
      }
    ];
    this.chartDataLabUser = [
      {
        label: 'In Progress',
        color: '#FF5A00',
        value: get(testSummaryCount, `data.InProgress`, 0)
      },
      {
        label: 'Completed',
        color: '#87E300',
        value: get(testSummaryCount, `data.Completed`, 0)
      }
    ];
    return isLabUser ? this.chartDataLabUser : this.chartData;
  }
  filterValues = value => {
    if (value) {
      window.sessionStorage.setItem("filterParams", JSON.stringify(value))
    }
  }
  showFilterButtonDashboard = status => this.setState({ showFilterButton: status });

  getRowDataForGrid(testData, favArr, selectedButton) {
    sessionStorage.removeItem("search_all");

    if (favArr) {
      testData.forEach(item => {
        let favObj = favArr.filter(dataObj => dataObj === item.trfId)[0] ? true : false;
        item.favExists = favObj;
        item.supplierName = item.supplierName === null ? item.facilityName:item.supplierName; 
      });

    }
    if (selectedButton === 'ActionRequired' || selectedButton === 'InProgress' || selectedButton === 'Favorites') {
      sessionStorage.setItem(selectedButton, JSON.stringify(testData));
    }

    return testData;
  }

  saveHistory = () => {
    const { gridColumns, sendFilterData, supplierName, selectedButton } = this.props;
    const { gridApi } = this.state;
    let isClearHistory = false;
    let differenceMonth = sessionStorage.getItem('currentSelectedMonth') ? sessionStorage.getItem('currentSelectedMonth') : 12;
    let day = new Date()
    day.setMonth(day.getMonth() - differenceMonth)
    let dateArray = day.toLocaleDateString('en-US').split('/')


    let fromDate = dateArray[1] + "-" + dateArray[0] + "-" + dateArray[2]

    gridApi.closeToolPanel();
    sendFilterData({ searchParams: { "filter": window.sessionStorage.filterParams ? JSON.parse(window.sessionStorage.filterParams) : '', "columns": gridColumns } }, isClearHistory, selectedButton, supplierName, fromDate);
  }
  clearHistory = () => {
    const { gridColumns, sendFilterData, supplierName, selectedButton, requestCancelCall } = this.props;
    const { gridApi } = this.state;
    let isClearHistory = true;
    let differenceMonth = sessionStorage.getItem('currentSelectedMonth') ? sessionStorage.getItem('currentSelectedMonth') : 12;
    let day = new Date()
    day.setMonth(day.getMonth() - differenceMonth)
    let dateArray = day.toLocaleDateString('en-US').split('/')


    let fromDate = dateArray[1] + "-" + dateArray[0] + "-" + dateArray[2]

    //requestFilterChage([]);
    sessionStorage.setItem('filterParams', [])
    sessionStorage.removeItem(selectedButton)

    sendFilterData({ searchParams: { "filter": [], "columns": gridColumns } }, isClearHistory, selectedButton, supplierName, fromDate);
    gridApi.closeToolPanel();
    gridApi.setFilterModel(null);
    gridApi.onFilterChanged();
    requestCancelCall(false)
    // requestTestSummary(selectedButton, supplierName, fromDate);
  }
  resetColumns = () => {
    let differenceMonth = sessionStorage.getItem('currentSelectedMonth') ? sessionStorage.getItem('currentSelectedMonth') : 12;
    let day = new Date()
    day.setMonth(day.getMonth() - differenceMonth)
    let dateArray = day.toLocaleDateString('en-US').split('/')

    let fromDate = dateArray[1] + "-" + dateArray[0] + "-" + dateArray[2]

    const { gridApi, params } = this.state;
    const { isLabUser, isSupplierUser, isFactoryUser, sendFilterData, supplierName, selectedButton } = this.props;
    const factoryDefaultColumns = ["rsl", "supplierName", "materialName", "materialNumber", "materialType", 'refId', "overAllStatus", "action", "favourites"];
    const supplierDefaultColumns = ["rsl", "materialName", "materialNumber", "materialType", 'refId', "overAllStatus", "favourites", "action"];
    const labUserDefaultColumns = ["rsl", "supplierName", "action", "labRequested", "labLocationId", "reviewAction", "proceedAction"];
    const rslUserDefaultColumns = ["rsl", "supplierName", "materialName", "materialNumber", 'refId', "brandInfo", "productType", "defaultTest", "testingType", "overAllStatus", "action", "favourites"];
    let defaultGridColumns = isSupplierUser ? supplierDefaultColumns : isLabUser ? labUserDefaultColumns : isFactoryUser ? factoryDefaultColumns : rslUserDefaultColumns;
    gridApi.closeToolPanel();
    sendFilterData({ searchParams: { "filter": window.sessionStorage.filterParams ? JSON.parse(window.sessionStorage.filterParams) : '', "columns": defaultGridColumns } }, false, selectedButton, supplierName, fromDate);
    params.columnApi.getAllColumns().map(item => {
      const visible = defaultGridColumns.indexOf(item.colId) > -1 ? true : false;
      return params.columnApi.setColumnVisible(item.colId, visible);
    });

  }
  onPaginationChanged = e => {
    const { params, statusChange } = this.state;
    const { selectedButton } = this.props;
    if (params && typeof params.api.paginationGetCurrentPage() === 'number' && !statusChange) {
      let pageNumber = sessionStorage.getItem(`${selectedButton}PageNumber`) ? sessionStorage.getItem(`${selectedButton}PageNumber`) : 0;

      if (selectedButton === 'ActionRequired') {
        if (e.newPage) {
          sessionStorage.setItem('ActionRequiredPageNumber', params.api.paginationGetCurrentPage())
        } else if (e.newData) {
          params && params.api.paginationGoToPage(Number(pageNumber))
        }
      } else if (selectedButton === 'InProgress') {
        if (e.newPage) {
          sessionStorage.setItem('InProgressPageNumber', params.api.paginationGetCurrentPage())
        } else if (e.newData) {
          params && params.api.paginationGoToPage(Number(pageNumber))
        }
      } else if (selectedButton === 'Completed') {
        if (e.newPage) {
          sessionStorage.setItem('CompletedPageNumber', params.api.paginationGetCurrentPage())
        } else if (e.newData) {
          params && params.api.paginationGoToPage(Number(pageNumber))
        }
      } else if (selectedButton === 'All') {
        if (e.newPage) {
          sessionStorage.setItem('AllPageNumber', params.api.paginationGetCurrentPage())
        } else if (e.newData) {
          params && params.api.paginationGoToPage(Number(pageNumber))
        }
      }
    }
    this.setState({ statusChange: false })
  }

  durationFetch = (month, status) => {

    const { requestTestSummary, requestTestSummaryCount, supplierName, requestDurationChange, requestApiCallOtherStatus,userDetails } = this.props;

    const { statusChange } = this.state;
    let day = new Date()
    day.setMonth(day.getMonth() - month)
    let dateArray = day.toLocaleDateString('en-US').split('/')

    let fromDate = dateArray[1] + "-" + dateArray[0] + "-" + dateArray[2]
    if (!statusChange && status !== 'Favorites') {
      requestTestSummaryCount(false, status, supplierName, fromDate,userDetails.facilityName)
    }
    requestApiCallOtherStatus()
    requestTestSummary(status, supplierName, fromDate, userDetails.facilityName)


    requestDurationChange({ month: month, statusWithMonth: { [status]: month } })
    //let oldItems = JSON.parse(sessionStorage.getItem('statusWithMonth'))
    //sessionStorage.setItem('statusWithMonth', JSON.stringify({ ...oldItems, ...statusWithMonth, ...{ [status]: month } }));
    sessionStorage.setItem('currentSelectedMonth', month);
    this.setState({ statusChange: false })
    if (month === 3) {
      this.setState({ threeMonthActive: true, twoYearActive: false, oneYearActive: false })
    } else if (month === 24) {
      this.setState({ threeMonthActive: false, twoYearActive: true, oneYearActive: false })
    } else if (month === 12) {
      this.setState({ threeMonthActive: false, twoYearActive: false, oneYearActive: true })
    }

  }
  fetchTestSummary = (status, supplierName, statusLoadFinish) => {
    const { requestTestSummary, requestTestSummaryCount, requestCancelCall,userDetails } = this.props;
    const { gridApi } = this.state;
    if (status) {
      requestCancelCall(true)
    }

    let differenceMonth = sessionStorage.getItem('currentSelectedMonth') ?
      sessionStorage.getItem('currentSelectedMonth') : 12;
    let day = new Date()
    day.setMonth(day.getMonth() - differenceMonth)
    let dateArray = day.toLocaleDateString('en-US').split('/')
    sessionStorage.setItem('activeStatus', status);

    let fromDate = status !== 'Favorites' ? dateArray[1] + "-" + dateArray[0] + "-" + dateArray[2] : null;

    if (Number(differenceMonth) === 3) {
      this.setState({ threeMonthActive: true, twoYearActive: false, oneYearActive: false })
    } else if (Number(differenceMonth) === 24) {
      this.setState({ threeMonthActive: false, twoYearActive: true, oneYearActive: false })
    } else if (Number(differenceMonth) === 12) {
      this.setState({ threeMonthActive: false, twoYearActive: false, oneYearActive: true })
    }
    this.setState({ statusChange: true })

    if (status !== 'All') {

      requestTestSummary(status, supplierName, fromDate,userDetails.facilityName)
      // requestCancelCall(false)
    }
    if (status !== 'Favorites') {
      requestTestSummaryCount(false, status, supplierName, fromDate,userDetails.facilityName)
    }
    let testNumber = sessionStorage.getItem(`${status}PageNumber`) ? sessionStorage.getItem(`${status}PageNumber`) : 0;
    gridApi && gridApi.paginationGoToPage(Number(testNumber))

  }
  searchAllDetails = path => {
    sessionStorage.removeItem("ActionRequired");
    sessionStorage.removeItem("InProgress");
    sessionStorage.removeItem("Favorites");
    const { requestApiCallOtherStatus, requestClearSearchAllData, history } = this.props;
    requestApiCallOtherStatus()
    requestClearSearchAllData(null)

    history.push(path, { page: 'dashboard' })

  }
  validateActionCenterUser = () => {
    const { userDetails } = this.props;
    // for new user
    if((userDetails.group === "Suppliers" || userDetails.group === "Factories") &&
    userDetails.userInactive === true && !userDetails.deactivated){
    return true
    }
    if ((userDetails.savedUserType === "Suppliers" || userDetails.savedUserType === "Factories") &&
      userDetails.userInactive === true &&
      (userDetails.OSHStatus === "not requested" || userDetails.OSHStatus === null) && (userDetails.OSHId === ""
        || userDetails.OSHId === null)) {
      return true
    }
    if ((userDetails.savedUserType === "Suppliers" || userDetails.savedUserType === "Factories") &&
      userDetails.userInactive === true
      && userDetails.OSHStatus === "pending" && userDetails.OSHId !== "" && userDetails.LitmosStatus === "pending" && userDetails.litmos !== null) {
      return true
    }
    if ((userDetails.savedUserType === "Suppliers" || userDetails.savedUserType === "Factories") &&
      userDetails.userInactive === false &&
      (userDetails.OSHStatus === null || userDetails.OSHStatus === "not requested" || userDetails.OSHStatus === "pending" ||
       userDetails.LitmosStatus === "pending" || userDetails.LitmosStatus === null) &&
      (userDetails.OSHId !== "" || userDetails.OSHId === null || userDetails.litmos === null)) {
      return true
    }
    return false;
  }

  render() {
    const {
      history,
      testData,
      //requestTestSummary,
      selectedButton,
      isLabUser,
      isNloUser,
      userExist,
      getTestSummaryNextPage,
      getTestSummaryTotalPage,
      supplierName,
      // filterParams,
      getFavourites,
      setDisplayColumns,
      gridColumns,
      initgridColumns,
      statusLoadFinish,
      isDashboardloading,
      isRslUser,
      enableDashboard,
      //disableClearButton
    } = this.props;
    const { status, submitModal, showFilterButton, threeMonthActive, twoYearActive, oneYearActive, isDashboardloadingState } = this.state;
    return (
      this.validateActionCenterUser() && !enableDashboard ? <ActionCenter /> :
        <div className="dashboard">
          <div className="page-align-items">
            <div className="ncss-row vertical-align-baseline">
              <div className="ncss-col-sm-8 page-header">
                <H1 className="text-color-accent u-bold page-title-has-back main-heading">
                  {this.getPageTitle()}
                </H1>
              </div>
              {// Create trf button should not be shown for Lab and NLO users 
                userExist && !isLabUser && !isNloUser ? (
                  <div className="ncss-col-sm-4 page-header button-top-adjust">
                    <Button
                      onClick={() => {
                        history.push('/trf',{preLoadMaterial:false});
                      }}
                    >
                      {pageConstants.trfButton}
                    </Button>
                  </div>
                ) : (
                  ''
                )}
              <Container section className="p0-top-sm m0-sm">
                <div className="ncss-row status-box">
                  {getTestSummaryNextPage <= getTestSummaryTotalPage ?
                    <div className="ncss-col-sm-12 progress_bar">
                      <ProgressBar percent={(getTestSummaryNextPage / getTestSummaryTotalPage) * 100}
                        message={"Loading"} />
                    </div>
                    : null
                  }
                  <div className="ncss-col-sm-12 status-area">
                    <StatusArea
                      status={this.createStatusAreaData()}
                      supplierName={supplierName}
                      ActionRequiredButtonStatus={statusLoadFinish}
                      requestTestSummary={this.fetchTestSummary}
                      requestSearchAll={this.searchAllDetails}
                    />
                  </div>
                  <div className="ncss-col-sm-4 chart-area">
                    <DonutChart chartData={this.createChartData()} title={pageConstants.chartTitle} />
                  </div>
                </div>
                {testData && showFilterButton && <div className="ncss-col-sm-12 filter-class">
                  {selectedButton !== 'Favorites' ?
                    <span>
                      <span className='time-duration-btn-text' >Data Source (Months):</span>
                      <span className={`${threeMonthActive ? 'active-time-duration-btn' : 'time-duration-btn'}`} onClick={() => this.durationFetch(3, selectedButton)}>3</span>
                      <span className='time-duration-btn-text'>|</span>
                      <span className={`${oneYearActive ? 'active-time-duration-btn' : 'time-duration-btn'}`} onClick={() => this.durationFetch(12, selectedButton)}>12</span>
                      <span className='time-duration-btn-text'>|</span>
                      <span className={`${twoYearActive ? 'active-time-duration-btn' : 'time-duration-btn'}`} onClick={() => this.durationFetch(24, selectedButton)}>24</span>
                    </span>
                    :
                    null}
                  <button type="button" className="ncss-btn-black ncss-brand u-uppercase save-history-btn" onClick={() => this.resetColumns()} ><i className="fas fa-trash-alt"></i> RESET COLUMNS</button>
                  <button type="button" className="ncss-btn-black ncss-brand u-uppercase save-history-btn" onClick={() => this.clearHistory()}><i className="fas fa-trash-alt"></i> CLEAR FILTERS</button>
                  <button type="button" className="ncss-btn-black ncss-brand u-uppercase save-history-btn" onClick={() => this.saveHistory()} ><i className="fas fa-list-ul"></i> SAVE COLUMNS/FILTERS</button>
                </div>}
                {testData ? (
                  <div className="ncss-row">
                    <div className="ncss-col-sm-12">
                      <Grid
                        onCellClicked={this.onCellClicked.bind(this)}
                        rowData={
                          statusLoadFinish[selectedButton] && sessionStorage.getItem(selectedButton) ?
                            JSON.parse(sessionStorage.getItem(selectedButton))
                            :
                            this.getRowDataForGrid(get(testData, `data.statusData[${selectedButton}]`, []), getFavourites, selectedButton)
                        }
                        columnDefs={isLabUser ? dashboardConfig.filter(item => item.colId !== 'favourites') :
                          isRslUser ? dashboardConfig.filter(item => (item.colId !== 'reviewAction' && item.colId !== 'proceedAction')) :
                            dashboardConfig.filter(item => (item.colId !== 'reviewAction' && item.colId !== 'proceedAction' && item.colId !== 'labAccept'))}
                        //columnDefs={isLabUser ? testGridConfig.filter(item => item.colId !== 'favourites') : testGridConfig.filter(item => (item.colId !== 'reviewAction' && item.colId !== 'proceedAction'))}
                        onRowClick={this.onRowClick}
                        onPaginationChanged={this.onPaginationChanged}
                        setParams={this.setGridParams}
                        getfilterValue={this.filterValues}
                        showFilterButtonDashboard={this.showFilterButtonDashboard}
                        searchParams={window.sessionStorage.filterParams ? JSON.parse(window.sessionStorage.filterParams) : ''}
                        filterKeepAlive
                        setDisplayColumns={setDisplayColumns}
                        gridColumns={gridColumns}
                        initgridColumns={initgridColumns}
                        overlayLoadingTemplate={

                          isDashboardloading
                            ? "<span class='ag-overlay-loading-center'>Please wait while your rows are loading</span>"
                            : "<span class='custom-no-row'>Please Select <strong> Location </strong>for Material list</span>"
                        }
                        customNoRowTemplete={
                          (testData && testData.length === 0) ?
                            "<span class='ag-overlay-loading-center'>Too Many Records Retrieved</span>" :
                            (testData === null) || (testData && get(testData, `data.statusData[${selectedButton}]`, []).length === 0 && !isDashboardloadingState)
                              ? "<span class='ag-overlay-loading-center'>No results found</span>"
                              : "<span class='ag-overlay-loading-center'>Please wait while your rows are loading</span>"

                        }
                      />

                      {submitModal &&
                        <ConfirmationBox
                          message={

                            status === 'submit'
                              ? 'Are you sure you want to proceed to test? This action will lock the TRF and the TRF can no longer be edited'
                              : 'Are you sure you want to cancel the Test ? '
                          }
                          submitModal={submitModal}
                          submitValue={
                            status === 'submit'
                              ? () => { this.updatestatusValue() }
                              : () => this.cancelstatus()
                          }
                          closeModal={this.closeSubmitModal}
                        />}

                    </div>
                  </div>
                ) : <Spinner large />}
              </Container>
            </div>
          </div>
        </div >
    );
  }
}

Dashboard.propTypes = {
  getTrfData: PropTypes.func,
  history: PropTypes.object,
  testData: PropTypes.object,
  requestTestSummary: PropTypes.func,
  requestTestSummaryCount: PropTypes.func,
  testSummaryCount: PropTypes.object,
  selectedButton: PropTypes.string,
  isSupplierUser: PropTypes.bool,
  isFactoryUser: PropTypes.bool,
  isLabUser: PropTypes.bool,
  isRslUser: PropTypes.bool,
  isNloUser: PropTypes.bool,
  userExist: PropTypes.bool,
  updatestatusValue: PropTypes.func
};

export const mapStateToProps = state => {
  return {
    supplierName: supplierName(state),
    userId: selectUserDetails(state).userId,
    selectedButton: getSelectedButton(state),
    filterParams: getfliterParams(state),
    testData: getTestData(state),
    testSummaryCount: getTestSummaryCount(state),
    isSupplierUser: isSupplierUser(state),
    isLabUser: isLabUser(state),
    isRslUser: isRslUser(state),
    isFactoryUser: isFactoryUser(state),
    isNloUser: isNloUser(state),
    userExist: !!selectUserDetails(state).userId,
    trfResult: state.trf.trfData,
    getTestSummaryTotalPage: getTestSummaryTotalPage(state),
    getTestSummaryNextPage: getTestSummaryNextPage(state),
    getFavourites: getFavourites(state),
    gridColumns: getGridColumns(state),
    initgridColumns: getInitGridColumns(state),
    selectedButtonFlag: getSelectedButtonFlag(state),
    statusLoadFinish: buttonStatus(state),
    duration: getDuration(state),
    statusWithMonth: getStatusWithMonth(state),
    isDashboardloading: state.dashboard.isDashboardloading,
    loginAuthFinish: loginAuthFinish(state),
    enableDashboard: getEnableDashboard(state),
    userDetails: selectUserDetails(state)
    //disableClearButton:getDisableClearButton(state)
  }
};

export const mapDispatchToProps = dispatch => ({
  requestTestSummary: (status, supplierName, fromDate,facilityName) => {
    // Action for Making the status button as selected (Action required, In progress or completed)
    dispatch(selectStatusButton(status));
    // Get data to show on grid
    dispatch(createServiceAction('testSummary', 'requested')({ status: status, supplierName: supplierName, fromDate: fromDate,facilityName:facilityName}));

  },
  requestFilterChage: (params) => {
    dispatch(filterChanged(params))
  },
  requestApiCallOtherStatus: () => {
    dispatch(searchAllPage())
  },
  requestClearSearchAllData: (params) => {
    dispatch(clearArchive(params))
  },
  requestDurationChange: (months) => {
    dispatch(durationChanged(months))
  },
  requestCancelCall: (status) => {
    dispatch(cancelCalls(status))
  },
  requestTestSummaryCount: (isClearHistory, status, supplierName, fromDate,facilityName) => {
    // Get data to show on graph and count
    dispatch(createServiceAction('testSummaryCount', 'requested')({ isClearHistory: isClearHistory, status: status, supplierName: supplierName, fromDate: fromDate,facilityName:facilityName }));
  },
  updateStatus: (data) => {
    dispatch(createServiceAction('updateStatus', 'submitted')(data));
  },
  getTrfData: trfId => {
    dispatch(createServiceAction('trfDetails', 'requested')(trfId));
  },
  updateFavourites: (data) => {
    dispatch(createServiceAction('updateFavourites', 'submitted')(data));
  },
  sendFilterData: (data, isClearHistory, status, supplierName, fromDate) => {

    dispatch(createServiceAction('saveFilterData', 'requested')({ data: data, isClearHistory: isClearHistory, status: status, supplierName: supplierName, fromDate: fromDate }));
  },
  setDisplayColumns: data => {
    dispatch(dispalyColumnsChange(data));
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Dashboard)
);